<template>
  <div class="consultation-table-wrap">
    <van-nav-bar class="van-nav-bar--fixed" title="查询助手" left-arrow  right-text="开奖记录"  @click-left="onClickLeft"  @click-right="onClickRight"></van-nav-bar>
     
    <div class="tab-wrap">
        <div class="tab-item" @click="showPopup()">查询年份<i class="icon iconfont icon-sort-down"></i></div>
            <div class="tab-item" @click="onOpenFilter()">查询条件<i class="icon iconfont icon-sort-down"></i></div></div>

    <div class="table-wrap" :style="open_filter? 'overflow-y: hidden;':'overflow-y: auto;margin-top: 0px;'">
      <!--  <div class="label-qs clearfix"><input id="checkBoxsel" type="checkbox"><label
                for="checkBoxsel">仅显示出现的期数</label><span class="to-notice">?</span></div> -->
        <table id="tableSvg">
            <tr style="line-height: 28px;">
                <th>年份/期数</th>
                <th>一</th>
                <th>二</th>
                <th>三</th>
                <th>四</th>
                <th>五</th>
                <th>六</th>
                <th>特码</th>
            </tr>
            <tr v-for="item in list" :key="item.id">
                <td class="">
                    <div class="">{{ item.year_name }}-{{ item.journal_name }}</div>
                </td>
                <td>
                    <div :class="item.normal1.color? 'redcolor '+item.normal1.color:''">{{ item.normal1.value }}</div>
                </td>
                <td class="">
                    <div :class="item.normal2.color? 'redcolor '+item.normal2.color:''">{{ item.normal2.value }}</div>
                </td>
                <td class="">
                    <div :class="item.normal3.color? 'redcolor '+item.normal3.color:''">{{ item.normal3.value }}</div>
                </td>
                <td class="">
                    <div :class="item.normal4.color? 'redcolor '+item.normal4.color:''">{{ item.normal4.value }}</div>
                </td>
                <td class="">
                    <div :class="item.normal5.color? 'redcolor '+item.normal5.color:''">{{ item.normal5.value }}</div>
                </td>
                <td class="">
                    <div :class="item.normal6.color? 'redcolor '+item.normal6.color:''">{{ item.normal6.value }}</div>
                </td>
                <td class="">
                    <div :class="item.special.color? 'redcolor '+item.special.color:''">{{ item.special.value }}</div>
                </td>
            </tr>
            
        </table>
        <div id="chart_svg"></div>
        <div class="sel-type" style="height: 100vh;" v-if="open_filter">
        <div class="sel-type-body">
            <div class="left-type">
                <div v-for="item in attribute" :key="item.id" :class="item.id==type? 'active':''" @click="onAttribute(item.id)">{{ item.name }}<i v-if="item.id==type" class="icon iconfont icon-caret-right active"></i></div>
            </div>
            <div class="right-sel" v-for="items in attribute" :key="items.id" :style="items.id==type?'display: block':'display: none'">
                <div :class="value.indexOf(item.id)>=0?'active':''" v-for="item in items.model" :key="item.id" @click="onAttributeValue(item.id)">{{item.name}}
                    <i class="icon iconfont icon-check" v-if="value.indexOf(item.id)>=0"></i>
                </div>
            </div>
        </div>
        <div class="btn-wrap">
            <div @click="onOpenFilter()">取消</div>
            <div class="active" @click="onSubmit()">确定</div>
        </div>
    </div>
    </div>

        <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">  
             <van-picker
            title="年份"
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            />
        </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { Dialog } from 'vant';
export default {
  name: 'helper',
  data() {
    return {
        type:0,
        value:[],
        show: false,
        open_filter:false,
        year_id:1,
        year_name:'2023',
        lotto_id:2,
        active: 'helper',
        list:[],
        attribute:[],
        loading: false,
        finished: false,
        iserror:false,
        refreshing:false,
        columns: [],
        page:0,
        message:''
    };
  },
  mounted() {
        this.lotto_id= parseInt(this.$route.query.lotto_id) ? parseInt(this.$route.query.lotto_id)  : 2;
        this.getData();
    },
  methods: {
        onSubmit(){
            this.open_filter=false;
            this.getData();
        },
        onAttributeValue(id){
            if(this.value.indexOf(id)>=0){
                this.value.splice(this.value.indexOf(id),1);
            }else{
                this.value.push(id);
            }

            if(this.value.length>3){
                Dialog({ message: '只能选择三个属性' });
                this.value.splice(this.value.indexOf(id),1);
            }
        },
        onAttribute(id){
            this.value=[];
            this.type=id;
        },
        onConfirm(value) {
            //Toast(`当前值：${value.id}`);
            this.year_name=value.text;
            this.year_id=value.id;
            this.show = false;
            this.getData();
        },
        onCancel() {
            this.show = false;
        },
        onOpenFilter(){
            if(this.open_filter){
                this.open_filter=false;
            }else{
                this.open_filter=true;
            }
        },
        showPopup() {
            this.show = true;
        },
        onClickLeft() {
            // 返回上一级页面
            this.$router.back();
        },
        onClickRight() {
            this.$router.push({ path: '/kaijiang'});
        },
        getData() {
            Toast.loading({
            message: '加载中...',
            forbidClick: true,
            });
            this.$http
                .get("api/helper/index?year_name="+this.year_name+"&type="+this.type+"&value="+this.value.join(",")+"&lotto_id="+this.lotto_id)
                .then(function (response) {
                   
                    if (response.data.code == "ok") {
                        this.list = response.data.data.list;
                        this.columns=response.data.data.year_list;
                        this.attribute=response.data.data.attribute;
                    } else {
                        this.iserror = true;
                        this.message = response.data.msg;
                    }
                    this.loading = false;
                    Toast.clear();
                    //console.log(this.list);
                });
    
        }
    }
}
</script>
<style>
.consultation-table-wrap{
    background-color: #fff;
}
.table-wrap {
    margin-bottom: 50px;
    padding-top: 0px;
    padding-bottom: 50px;
}
</style>
